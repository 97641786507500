.container {
  display: flex;
  flex-direction: column;
  // border: 1px solid red;
  height: 100%;
}

.table {
  cursor: pointer;
  flex-grow: 1;
}
