.wrapper {
  position: relative;
  display: grid;
  gap: 28px;
  border: 1px solid #ced0d7;
  border-radius: 8px;
  padding: 12px;
}

.twoColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.footerClaim {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  background-color: #ecf0f5;
  border-radius: 8px;
  padding: 12px 0;
}

.inputImg {
  z-index: 2;
  width: 24px;
  height: 24px;
  margin-top: 25px;
  margin-left: 18px;
}

.deleteButton {
  align-self: center;
  position: absolute;
  right: -61px;
  top: 10px;
}

.errorText {
  margin: 0;
  padding: 0 0 0 20px;
  font-size: 12px;
  color: rgb(236, 62, 114);
  line-height: 16px;
}
