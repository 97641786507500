.createT {
  width: 766px !important;

  .form {
    display: flex;
    flex-direction: column;

    .row {
      width: 100%;
      display: flex;
      gap: 32px;
    }
  }

  [class~='ant-btn-primary'] {
    margin-left: 24px !important;
    width: 178px;
    border: none;

    &:hover {
      background-color: #686f88;
      border: none;
    }
  }
}

.classFullWidth {
  width: 100%;
}
