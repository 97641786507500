.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .center {
    .title {
      color: var(--grey-gray-13-total-black, #090909);
      font-family: Muller;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .tablesWrapper {
      width: 934px;
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: flex-end;

      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .firstRow {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .row {
        color: var(--grey-gray-13-total-black, #090909);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .table1,
      .table2,
      .table3 {
        width: 290px;
      }

      .table1 [class~='ant-table-cell'],
      .table2 [class~='ant-table-cell'],
      .table3 [class~='ant-table-cell'] {
        padding: 8px 6px 8px 16px !important;
      }
    }

    .subTitle {
      margin: 32px 0px 12px 0px;
      color: #090909;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .table4 [class~='ant-table-body'] {
      // min-height: 221px;
      overflow-y: hidden !important;
    }

    .link {
      color: #7145ec;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .total {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: var(--grey-gray-13-total-black, #090909);
      font-family: Muller;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .card {
      width: 170px;
      border-radius: 8px;
      background: #fff;

      .cardTitle {
        height: 61px;
        text-align: right;
        padding: 10px 16px;
        display: flex;
        justify-content: flex-end;
        color: var(--grey-gray-10-blue-grey, #686f88);
        font-family: Muller;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 1px solid var(--grey-gray-4-stroke-input, #eaedf4);
      }

      .moneyRow {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 16px;
        height: 40px;

        .value {
          color: var(--grey-gray-13-total-black, #090909);
          font-family: Muller;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
