.row {
  display: grid;
  gap: 32px;
}

.twoColumnsBlock {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.twoFlexColumnsBlock {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.contentBalance {
  font-weight: 700;
}

.accordionButton {
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  text-align: start;
  font-size: 20px;
  line-height: 24px;
  color: #29292c;
  gap: 8px;
  cursor: pointer;
}

.generalBottom {
  width: max-content;
  height: 40px;

  &:hover .dropZoneGeneralImg {
    filter: invert(37%) sepia(28%) saturate(314%) hue-rotate(199deg) brightness(92%) contrast(83%);
  }
}

.googleDocs {
  height: 56px;
  background: #fff;
  border-radius: 8px;
  padding: 0 0 0 16px;
  display: flex;
  align-items: center;

  .label {
    width: 176px;
    color: var(--grey-gray-10-blue-grey, #686f88);

    /* Body2 - 16 Medium */
    font-family: Muller;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  .value {
    width: 100%;
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
  }
}
