.title {
  display: flex;
  justify-content: space-between;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
