.wrapper {
  max-width: 894px;
}

.filter {
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
}

.tableRowStyle {
  background-color: #fff;
  cursor: pointer;

  & > td {
    border: none;
  }

  &:hover {
    cursor: pointer;
    background-color: #fbfbfd;
  }

  // .tableIconBtn {
  //   display: none;
  //   cursor: pointer;
  // }

  // &:hover .tableIconBtn {
  //   display: inline;
  // }

  &:hover .actionTableCell {
    opacity: 1;
  }
}

.tableHeadCell {
  background-color: #fff !important;
  height: 27px;
}

.tableCellSmall {
  width: 60px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.link {
  text-decoration: underline;
}

.noFiles {
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #fff;

  color: #4e5169;
  font-family: Lato !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &Menu {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &Item {
      height: 36px;
      color: var(--grey-gray-11-table-black, #4e5169);

      font-family: Muller;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.actionTableCell {
  opacity: 0;
  transition: opacity 0.4s;
}
