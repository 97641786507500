.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.headerTitle {
  margin-bottom: 12px;
}

.headerWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 12px;
  gap: 20px;
  width: 100%;

  &Row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    &Left {
      display: flex;
      gap: 20px;
    }
  }
}

.menuWrapper {
  position: absolute;
  right: -60px;
  bottom: 12px;

  & label {
    white-space: nowrap;
  }
}

.downloadHeaderButton {
  right: -60px;
  cursor: pointer;
  border: 1px solid #a7afb7;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.row {
  cursor: pointer;
}

.rowDeleted {
  background-color: var(--red-1) !important;
}
