// General
.root [class~='ant-table'] {
  line-height: 20px;
  color: var(--gray-13);
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 2px;
}

.root [class~='ant-table-tbody'] > tr:last-child > td {
  border-bottom: 0;
}

.root [class~='ant-table-thead'] > tr > th {
  padding: 10px 16px;
  color: var(--gray-10);
  font-weight: 500;
  vertical-align: bottom;
}

.root [class~='ant-table-tbody'] > tr > td {
  vertical-align: top;
  border-bottom: none;
}

// Small
.root [class~='ant-table'][class~='ant-table-small'] [class~='ant-table-thead'] > tr > th {
  padding-top: 18px;
  padding-bottom: 18px;
}

// Sort
.root [class~='ant-table-thead'] > tr > th[class~='ant-table-column-sort'] {
  color: var(--gray-13);
}

.root [class~='ant-table-thead'] th[class~='ant-table-column-sort'] {
  background-color: var(--gray-2);
}

.root [class~='anticon-caret-up'],
.root [class~='anticon-caret-down'] {
  position: relative;
}

.root [class~='anticon-caret-up'] > svg,
.root [class~='anticon-caret-down'] > svg {
  display: none;
}

.root [class~='anticon-caret-up']::before,
.root [class~='anticon-caret-down']::before {
  position: absolute;
  top: -6px;
  left: -12px;
  z-index: 1;
  display: block;
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}

.root [class~='anticon-caret-up'][class~='active']::before {
  content: '';
  top: -9px;
  rotate: 180deg;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGgKICAgIGZpbGxSdWxlPSJldmVub2RkIgogICAgY2xpcFJ1bGU9ImV2ZW5vZGQiCiAgICBkPSJNNS4wMDAwMSAxQzUuMDAwMDEgMC40NDc3MTUgNC41NTIzIDAgNC4wMDAwMSAwQzMuNDQ3NzMgMCAzLjAwMDAxIDAuNDQ3NzE1IDMuMDAwMDEgMVY4SDEuMjA3MTJDMC43NjE2NjUgOCAwLjUzODU4MiA4LjUzODU3IDAuODUzNTY1IDguODUzNTVMMy42NDY0NiAxMS42NDY0QzMuODQxNzIgMTEuODQxNyA0LjE1ODMgMTEuODQxNyA0LjM1MzU2IDExLjY0NjRMNy4xNDY0NiA4Ljg1MzU1QzcuNDYxNDQgOC41Mzg1NyA3LjIzODM2IDggNi43OTI5IDhINS4wMDAwMVYxWiIKICAgIGZpbGw9IiMwMDAwMDAiCiAgLz4KPC9zdmc+Cg==');
}

.root [class~='anticon-caret-down'][class~='active']::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGgKICAgIGZpbGxSdWxlPSJldmVub2RkIgogICAgY2xpcFJ1bGU9ImV2ZW5vZGQiCiAgICBkPSJNNS4wMDAwMSAxQzUuMDAwMDEgMC40NDc3MTUgNC41NTIzIDAgNC4wMDAwMSAwQzMuNDQ3NzMgMCAzLjAwMDAxIDAuNDQ3NzE1IDMuMDAwMDEgMVY4SDEuMjA3MTJDMC43NjE2NjUgOCAwLjUzODU4MiA4LjUzODU3IDAuODUzNTY1IDguODUzNTVMMy42NDY0NiAxMS42NDY0QzMuODQxNzIgMTEuODQxNyA0LjE1ODMgMTEuODQxNyA0LjM1MzU2IDExLjY0NjRMNy4xNDY0NiA4Ljg1MzU1QzcuNDYxNDQgOC41Mzg1NyA3LjIzODM2IDggNi43OTI5IDhINS4wMDAwMVYxWiIKICAgIGZpbGw9IiMwMDAwMDAiCiAgLz4KPC9zdmc+Cg==');
}

.root
  [class~='ant-table-thead']
  th[class~='ant-table-column-has-sorters']:hover
  [class~='anticon-caret-up']:not([class~='active'])
  + [class~='anticon-caret-down']:not([class~='active'])::before {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGgKICAgIGZpbGxSdWxlPSJldmVub2RkIgogICAgY2xpcFJ1bGU9ImV2ZW5vZGQiCiAgICBkPSJNNS4wMDAwMSAxQzUuMDAwMDEgMC40NDc3MTUgNC41NTIzIDAgNC4wMDAwMSAwQzMuNDQ3NzMgMCAzLjAwMDAxIDAuNDQ3NzE1IDMuMDAwMDEgMVY4SDEuMjA3MTJDMC43NjE2NjUgOCAwLjUzODU4MiA4LjUzODU3IDAuODUzNTY1IDguODUzNTVMMy42NDY0NiAxMS42NDY0QzMuODQxNzIgMTEuODQxNyA0LjE1ODMgMTEuODQxNyA0LjM1MzU2IDExLjY0NjRMNy4xNDY0NiA4Ljg1MzU1QzcuNDYxNDQgOC41Mzg1NyA3LjIzODM2IDggNi43OTI5IDhINS4wMDAwMVYxWiIKICAgIGZpbGw9IiNDNUNBREIiCiAgLz4KPC9zdmc+Cg==');
}

.root [class~='anticon-caret-up'][class~='active'] + [class~='anticon-caret-down']::before {
  content: none;
}

.root [class~='ant-table-column-sorters'] {
  justify-content: flex-start;
}

.root [class~='ant-table-column-sorters'] [class~='ant-table-column-title'] {
  flex: none;
  margin-right: 10px;
}

.root [class~='ant-table-thead'] th[class~='ant-table-column-has-sorters']:hover {
  background-color: var(--gray-2);
}

// No data
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  color: var(--gray-10);
}
