.wrapper {
  position: relative;
  display: grid;
  align-content: start;
  gap: 32px;
  // max-width: 890px;
  margin-bottom: 60px;
}

.generalBottom {
  width: max-content;
  height: 40px;
  &:hover .dropZoneGeneralImg {
    filter: invert(37%) sepia(28%) saturate(314%) hue-rotate(199deg) brightness(92%) contrast(83%);
  }
}
