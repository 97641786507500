.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 950px;

  .titleWrapper {
    margin-bottom: 38px;
    align-self: flex-start;
  }

  .actionBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
  }

  .dataTable {
    // background: red !important;
  }
}

[class='ant-table-footer'] {
  display: flex;
  align-items: center;
  border-top: 1px solid #eaedf4;
  background: #fff !important;
  height: 40px;
  padding: 0 !important;
}
