.link {
  color: #7145ec;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}

.table {
}

.table [class~='ant-table-body'] {
  overflow-x: hidden !important;
}

.header {
  color: var(--grey-gray-10-blue-grey, #686f88);

  white-space: nowrap;
  font-family: Muller;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 934px;
  max-width: 1340px;

  .center {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .title {
      align-self: flex-start;
      color: #090909;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .filters {
      display: flex;
      gap: 12px;
      height: 40px;
      justify-content: space-between;
      // max-width: 934px;
    }

    .total {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: var(--grey-gray-13-total-black, #090909);
      font-family: Muller;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
