.wrapper {
  max-width: 894px;

  .downloadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 140px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #686f88;
    background: #f7f7fb;

    color: #686f88;
    text-align: right;
    font-family: Muller;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  .table {
    .fileRow {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      color: #7145ec;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }

    .fileRowNoData {
      display: flex;
      align-items: center;
      gap: 4px;
      // cursor: pointer;

      // color: #7145ec;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      // text-decoration-line: underline;
    }
  }
}

.del {
  background: #ec3e72 !important;
}
