.block {
  width: 451px;
  background: #fff;
  border-radius: 8px;

  &Row {
    height: 56px;

    &Label {
      padding: 16px 8px 16px 16px;
      width: 200px;

      white-space: nowrap;
      color: var(--grey-gray-10-blue-grey, #686f88);
      font-family: Muller;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    &Value {
      padding: 16px 0px 16px 16px;
      max-width: 210px;
      white-space: nowrap;
      overflow-y: auto;

      font-family: Muller;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &LinkValue {
      margin: 0px 16px 0 0;
      padding: 16px 0px 16px 16px;
      max-width: 210px;
      cursor: pointer;

      color: var(--purple-purple-6-hover, #825bf2);
      font-family: Muller;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}

.settings {
  border-radius: 8px 0px 0px 8px;
  background: var(--grey-gray-1-total-white, #fff);
  height: 56px;
  padding: 0px 16px;

  &Label {
    color: var(--grey-gray-10-blue-grey, #686f88);
    font-family: Muller;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &Value {
    color: var(--grey-gray-13-total-black, #090909);
    font-family: Muller;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.createT {
  width: 766px !important;
  background: red;

  [class~='ant-btn-primary'] {
    margin-left: 24px !important;
    width: 178px;
    border: none;

    &:hover {
      background-color: #686f88;
      border: none;
    }
  }

  [class~='ant-btn-default'] {
    border: 1px solid #686f88;
  }
}

.balanceRow {
  padding: 0px 16px;
  border-radius: 8px;
  height: 56px;
}
