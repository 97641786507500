.label,
.value {
  color: #686f88;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.link {
  color: #7145ec;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

.warning {
  color: #ec3e72;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
